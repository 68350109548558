<template>
  <div class="navbar-wrapper">
    <div class="d-flex align-items-center justify-content-between">
      <b-link
        class="brand-logo ml-2"
        to="/"
      >
        <GoZayaanBrandLogo />
      </b-link>
      <b-navbar
        class="header-navbar align-items-center"
      >
        <Navbar />
      </b-navbar>
    </div>
  </div>
</template>

<script>
import Navbar from '@/layouts/components/Navbar.vue'
import GoZayaanBrandLogo from '@/components/icons/GoZayaanBrandLogo.vue'

export default {
  name: 'TourNavbar',
  components: {
    Navbar,
    GoZayaanBrandLogo,
  },
}
</script>

<style lang="scss">
.navbar-wrapper {
  background-color: #fff;
  .brand-text {
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 1.45rem;
  }
  .header-navbar {
    justify-content: flex-end;
    .navbar-container {
      flex-basis: 250px;
      .navbar-nav .nav-menu{
        display: none;
      }
    }
  }
}
.dark-layout {
  .navbar-wrapper {
    background-color: #283046;
  }
}
</style>
